import './odometer.js'
import '../scss/style.scss';
import { Swiper, Autoplay, Navigation, Pagination, Lazy } from 'swiper';
import 'swiper/swiper-bundle.css';

// Configure Swiper to use modules
Swiper.use([Autoplay, Navigation, Pagination, Lazy]);

// SLider Textes
let slider = document.querySelector('.wpackio-theme-slider-text-slider');
if (slider) {
    new Swiper('.wpackio-theme-slider-text-slider', {
        slidesPerView: 1,
        pagination: {
            el: '.wpackio-theme-slider-text-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.wpackio-theme-slider-text-button-next',
            prevEl: '.wpackio-theme-slider-text-button-prev',
        },
    });
}

// SLider Photos
let sliderPhotos = document.querySelector('.wpackio-theme-slider-photos-swiper');
if (sliderPhotos) {
    new Swiper('.wpackio-theme-slider-photos-swiper', {
        preloadImages: false,
        lazy: {
            loadPrevNext: true,
        },
        speed: 600,
        autoplay: {
            delay: 4000,
        },
        loop: true,
        slidesPerView: 1,
        pagination: {
            el: '.wpackio-theme-slider-photos-pagination',
            type: 'bullets',
            clickable: true,
        },
    });
}

// Menu
//let nav = document.querySelector('#nav');

// Menu mobile
let mobileMenuButton = document.querySelector('#mobile-menu');
mobileMenuButton.addEventListener('click', function (event) {
    document.body.classList.toggle('is-menu-open');
    this.classList.toggle('active');
});

// Parallax
function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

let parallaxImages = document.querySelectorAll('.wpackio-theme-parallax-images-container');

function parallax() {
    parallaxImages.forEach(function (item) {
        if (item && isInViewport(item)) {
            item.classList.add('active'); 
        }
    }); 
}

window.addEventListener("scroll", function(){
	parallax();	
});

// Accordion
let accordions = document.querySelectorAll('.wpackio-theme-accordion');
if (accordions) {
    accordions.forEach(function (accordion) {
        let accordionItems = accordion.querySelectorAll('.wpackio-theme-accordion-item');        
        accordionItems.forEach(function (item) {
            let close = item.querySelector('.wpackio-theme-accordion-item-close');
            let open = item.querySelector('.wpackio-theme-accordion-item-open');
            let content = item.querySelector('.wpackio-theme-accordion-content');
            item.dataset.height = content.offsetHeight;
            content.style.height = 0;
            close.addEventListener('click', function (event) {        
                item.classList.remove('open');
                content.style.height = 0;
            });
            open.addEventListener('click', function (event) {
                item.classList.add('open');
                content.style.height = item.dataset.height + 'px';
            });            
        });
        accordionItems[0].classList.add('open');
        accordionItems[0].querySelector('.wpackio-theme-accordion-content').style.height = accordionItems[0].dataset.height + 'px';
    });
}

// Map
import L from 'leaflet'
import 'leaflet/dist/leaflet.css';

import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import 'leaflet.markercluster/dist/MarkerCluster.css';

import { OpenStreetMapProvider } from 'leaflet-geosearch';

let mapContainer = document.querySelector("#projects-map");
if (mapContainer) {
    const provider = new OpenStreetMapProvider();

    function updatePosition(id, lat, lng) {
        // Requête ajax pour mettre à jour la position
        const data = {
            action: 'update_project_position',
            projectId: id,
            lat: lat,
            lng: lng,
        };
        fetch(document.body.dataset.ajax, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams(data),
        })
        .then(response => response.json())
        .then(response => {});
    }

    function addMarker(item, group) {
        var icon = mapContainer.dataset.icon;
        if (item.terms.length == 1) {
            icon = item.terms[0].icon;
        }    
        var terms = '';
        item.terms.forEach(function (term) {
            terms += '<div class="term" style="color: ' + term.color + '">' + term.name + '</div>';
        });
        var html = '<img src="' + item.thumbnail + '" alt="' + item.title + '" />';
        html += '<div class="terms">' + terms + '</div>';
        html += '<div class="city">' + item.city + '</div>';
        html += '<div class="title">' + item.title + '</div>';
        html += '<div class="excerpt">' + item.excerpt + '</div>';
        html += '<a href="' + item.link + '" class="btn">Voir le projet</a>';

        var popup = L.popup({
            content: html,
            maxWidth: 180,
            minWidth: 180,   
            offset: [145, 240],
        });

        var icon = L.icon({
            iconUrl: icon,
            iconSize: [34, 45],
            iconAnchor: [17, 45],
        });

        if (!item.lat) {
            item.lat = 0;
        }
        if (!item.lng) {
            item.lng = 0;
        }        
        var marker = L.marker([item.lat, item.lng], {
            icon: icon,
            title: item.title,
            draggable: document.body.classList.contains('admin-bar') ? true : false,
        }).bindPopup(popup);
        if (document.body.classList.contains('admin-bar')) {
            marker.on('dragend', function(event) {
                var position = marker.getLatLng();
                updatePosition(item.id, position.lat, position.lng);
            });
        }
        group.addLayer(marker);
    }

    function addMarkers(data, group) {
        data.forEach(function (item, key) {
            if (!item.lat && !item.lng && item.address) {
                provider.search({ query: item.address }).then(function (result) {
                    if (result.length) {
                        item.lat = result[0].y;
                        item.lng = result[0].x;                          
                        addMarker(item, group);
                        updatePosition(item.id, item.lat, item.lng);
                    }
                    else {
                        alert('Pas de résultat pour ' + item.title + ' : ' + item.address);
                    } 
                });
            }
            else {
                addMarker(item, group);
            }  
        });
    }

    function filterMap(event) {
        event.preventDefault();
        let id = this.dataset.id;
        group.clearLayers();
        var data = JSON.parse(mapContainer.dataset.json);
        // Filtrer le tableau
        if (id != 'all') {
            data = data.filter(function (item) {
                var terms = item.terms.map(function (term) {
                    return term.id;
                });
                return terms.includes(parseInt(id));
            });
        }
        addMarkers(data, group);
    }

    var map = L.map('projects-map', {
        scrollWheelZoom: false,
        zoomControl: false
    }).setView([48.7942, 2.4115], 10);
    map.once('focus', function() { map.scrollWheelZoom.enable(); });

    L.control.zoom({
        position: 'topright'
    }).addTo(map);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);      
    
    var group = L.markerClusterGroup({
        disableClusteringAtZoom: 12,
        iconCreateFunction: function (cluster) {
            return L.divIcon({ className: 'map-cluster-icon', html: '<span>' + cluster.getChildCount() + '</span>', iconSize: L.point(40, 40) });
        }
    });
    var data = JSON.parse(mapContainer.dataset.json);
    addMarkers(data, group);
    map.addLayer(group);

    // Filtres
    const filters = document.querySelectorAll(".projects-map-filter");
    for (const filter of filters) {
        filter.addEventListener("click", filterMap);
    }
}

// Antibot
function antibotMove(e) {
    let antibots = document.querySelectorAll("[name='antibot']");
    antibots.forEach(function (item) {
        item.value = item.dataset.value;
    });    
}
document.querySelector('body').addEventListener('mousemove', antibotMove);
document.querySelector('body').addEventListener('touchmove', antibotMove);