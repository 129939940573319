import Odometer from 'odometer';
import 'odometer/themes/odometer-theme-minimal.css';

function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

let odometers = document.querySelectorAll('.odometer');

const launchOdometer = () => {
    odometers.forEach(function (item) {
        if (isInViewport(item)) {
            let od = new Odometer({
                el: item,
                value: 0,
                format: '( ddd).dd',
            });
            od.update(item.dataset.number)
        }
    });
};

launchOdometer();

document.addEventListener('scroll', function () {
    launchOdometer();
}, {
    passive: true
});