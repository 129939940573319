let videoContainer = document.getElementById('plan-video');
let video = document.getElementById('plan-video-object');

if (video) {
    video.controls = false;

    // Navigation
    let navigation = document.getElementById("plan-navigation");
    let index = navigation.dataset.index;
    let mode = 'autoplay';
    //let mode = 'normal';

    let prev = document.getElementById("plan-prev");
    let next = document.getElementById("plan-next");
    let current = document.getElementById("plan-pagination-current");

    prev.addEventListener("click", function () {
        mode = 'reverse';
        if (index >= 0) {
            index--;            
            if (index == -1) {
                index = 3;
            }
            navigation.dataset.index = index;
            current.innerHTML = index;
            switch (index) {
                case 3:
                    video.pause();
                    video.currentTime = 30;  
                break;
                case 2:
                    video.pause();
                    video.currentTime = 16.5;  
                    break;
                case 1:
                    document.getElementById("feel-link").classList.remove('active');
                    document.getElementById("make-link").classList.add('active');
                    video.pause();
                    video.currentTime = 7;   
                    break;
                case 0:
                    document.getElementById("make-link").classList.remove('active');
                    document.getElementById("move-link").classList.add('active');
                    video.pause();
                    video.currentTime = 0;   
                    break;
                default:
                    return;
            }
        }
        return;
    });

    next.addEventListener("click", function () {
        mode = 'normal';
        if (index <= 3) {
            index++;
            if (index == 4) {
                index = 0;
            }
            navigation.dataset.index = index;
            current.innerHTML = index;
            switch (index) {
                case 0:
                    video.pause();
                    video.currentTime = 0;                    
                break;
                case 1:
                    video.currentTime = 0;
                    video.play();
                    document.getElementById("move-link").classList.add('active');
                    break;
                case 2:
                    video.currentTime = 7;
                    video.play();
                    document.getElementById("move-link").classList.remove('active');
                    document.getElementById("make-link").classList.add('active');
                    break;
                case 3:
                    video.currentTime = 16.5;
                    video.play();
                    document.getElementById("make-link").classList.remove('active');
                    document.getElementById("feel-link").classList.add('active');
                    break;
                default:
                    return;
            }
        }
        return;
    });

    function updateTimeElapsed() {
        if (mode == 'normal') {
            if (index == 1 && video.currentTime > 7) {
                video.pause();
            }
            else if (index == 2 && video.currentTime > 16) {
                video.pause();
            }
        }
        else if (mode == 'autoplay') {
            if (video.currentTime > 16) {
                document.getElementById("make-link").classList.remove('active');
                document.getElementById("feel-link").classList.add('active');
                index = 3;
                navigation.dataset.index = index;
                current.innerHTML = index;
            }
            else if (video.currentTime > 7) {
                document.getElementById("move-link").classList.remove('active');
                document.getElementById("make-link").classList.add('active');
                index = 2;
                navigation.dataset.index = index;
                current.innerHTML = index;
            }
            else if (video.currentTime > 0) {
                document.getElementById("feel-link").classList.remove('active');
                document.getElementById("move-link").classList.add('active');
                index = 1;
                navigation.dataset.index = index;
                current.innerHTML = index;
            }
        }
    }
    video.addEventListener('timeupdate', updateTimeElapsed);

    video.addEventListener('ended', function () {
        if (mode == 'autoplay') {
            setTimeout(function () {
                video.currentTime = 0;
                video.play();
                index = 0;
                //navigation.dataset.index = index;
                //current.innerHTML = index;
            }, 2000);
        }
    });
}